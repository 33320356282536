import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Para Agendamento Autônomo
import SavingsIcon from '@mui/icons-material/Savings'; // Para Independência Financeira
import SpotlightIcon from '@mui/icons-material/EmojiEvents'; // Para Acesso Direto a Eventos
import StarIcon from '@mui/icons-material/StarBorder'; // Para Oportunidades para Novos Talentos

const features = [
  {
    Icon: CalendarTodayIcon,
    title: 'Agendamento Autônomo',
    description: 'Crie sua agenda de eventos no seu tempo. Descubra e reserve artistas diretamente, sem interrupções ou ligações desnecessárias.'
  },
  {
    Icon: SavingsIcon,
    title: 'Independência Financeira',
    description: 'Mantenha mais do seu cachê. Diga adeus às altas comissões de agências e tenha controle total sobre seus ganhos.'
  },
  {
    Icon: SpotlightIcon,
    title: 'Acesso Direto a Eventos',
    description: 'Liberte-se das restrições de agências. Acesse uma variedade de eventos e toque para públicos novos e mais amplos.'
  },
  {
    Icon: StarIcon,
    title: 'Oportunidades para Novos Talentos',
    description: 'Para os novatos no palco. Ganhe visibilidade e crie oportunidades sem necessidade de conexões prévias com agências.'
  },
];

const FeaturesSection = () => {
  return (
    <Box  sx={{ flexGrow: 1, padding: 4, backgroundColor: 'background.default' }}>
      <Grid container spacing={10} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} lg={3} key={index}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <feature.Icon sx={{ fontSize: 40, mb: 2 }} />
              <Typography variant="h6" component="h3" sx={{ marginBottom: 1 }}>
                {feature.title}
              </Typography>
              <Typography variant="body1">
                {feature.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturesSection;
