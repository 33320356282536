import React from 'react';
import { Typography, Box } from '@mui/material';
import aboutBackground from '../assets/5570834.jpg'; // Certifique-se de que o caminho está correto

const About = () => (
  <Box
    sx={{
      padding: 4,
      backgroundColor: 'rgba(0, 0, 0, 1)',
      color: 'text.primary',
      backgroundImage: `url(${aboutBackground})`,
      backgroundSize: 'cover', // Certifique-se de que a imagem cubra toda a área do componente
      backgroundPosition: 'center', // Centraliza a imagem
      backgroundRepeat: 'no-repeat', // Impede a repetição da imagem
    }}
  >
    <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
      Quem somos nós?
    </Typography>
    <Typography variant="body1" sx={{ mb: 4 }}>
      O GigHub revoluciona a forma como artistas e contratantes interagem. Livre-se dos intermediários e desfrute de uma plataforma que coloca o poder nas suas mãos - agende eventos, descubra novos talentos e construa sua carreira musical em seus próprios termos.
    </Typography>
  </Box>
);

export default About;
