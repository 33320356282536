import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const validateEmail = (email) => {
    return email.match(
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError('Por favor, insira um e-mail válido.');
      return;
    }
    setError('');
  
    try {
      const response = await fetch('https://gighub-server.onrender.com/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
  
      if (response.ok) {
        setMessage('Inscrição realizada com sucesso!');
        setEmail(''); // Limpa o campo de e-mail após a inscrição
      } else {
        // Ajuste das mensagens de erro de acordo com o JSON do backend
        setError(data.error === "email_already_subscribed" ? 'E-mail já inscrito.' : 'Erro ao enviar o formulário.');
      }
    } catch (error) {
      setError('Erro ao conectar com o servidor.'); // Este erro será exibido apenas se houver um problema na rede ou no próprio fetch
    }
  };

  return (
    <Box sx={{ textAlign: 'center', my: 8 }}>
      <Typography variant="h6" component="h3" gutterBottom>
        Cadastre-se para receber novidades.
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiTextField-root': { m: 1, width: '250px' },
          '& .MuiButton-root': { m: 1 },
        }}
      >
        <TextField
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
          helperText={error || message}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ flexShrink: 0 }}>
          Inscrever-se
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
