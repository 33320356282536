import React, { useRef } from 'react';
import { ThemeProvider, Container } from '@mui/material'; // Aqui está a importação corrigida
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import About from './components/About';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

function App() {
  const featuresSectionRef = useRef(null);

  const scrollToFeatures = () => {
    featuresSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HeroSection onScrollToFeatures={scrollToFeatures} />
      <About />
      <Container component="main" maxWidth="false">
        <div ref={featuresSectionRef}>
          <FeaturesSection />
        </div>
        <ContactForm />
        <Footer />
      </Container>
    </ThemeProvider>
  );
}

export default App;
