import React from 'react';
import { Typography, Box } from '@mui/material';

const Footer = () => (
  <Box sx={{ textAlign: 'center', margin: '4rem 0' }}>
    <Typography variant="body2">
      © 2024 GigHub. Todos os direitos reservados.
    </Typography>
    {/* Links para redes sociais ou informações de contato adicionais */}
  </Box>
);

export default Footer;
