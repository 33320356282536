import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import heroBackground from '../assets/1448.jpg';
import logo from '../assets/SVG/logo-white.svg'; 

const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'relative',
    height: '100vh',
    width: '100vw',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundImage: `url(${heroBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    zIndex: 1, // Garanta que isto seja menor que o zIndex do logo e do conteúdo
  },
  logo: {
    maxWidth: '220px',
    zIndex: 2, // Z-index maior que o do overlay para ficar acima
  },
  content: {
    zIndex: 2, // Z-index maior que o do overlay para ficar acima
    position: 'relative', // Importante para posicionar corretamente em relação à hero section
    marginTop: theme.spacing(4),
  },
  buttonContainer: {
    zIndex: 2, // Z-index maior que o do overlay para ficar acima
    position: 'relative', // Importante para posicionar corretamente em relação à hero section
    marginTop: theme.spacing(3),
  },
  // ... outros estilos
}));

const HeroSection = ({ onScrollToFeatures }) => {
  const classes = useStyles();

  return (
    <div className={classes.hero}>
      <div className={classes.overlay} />
      <img src={logo} alt="GigHub Logo" className={classes.logo} />
      <div className={classes.content}>
        <Typography variant="h3" color="secondary">
          Palcos Livres, Conexões Diretas
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
      <Button onClick={onScrollToFeatures} variant="contained" color="secondary">
          Saiba Mais
        </Button> 
        </div>
    </div>
  );
};

export default HeroSection;
