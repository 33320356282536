// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // Define o esquema de cores para escuro
    primary: {
      main: '#90caf9', // Cor primária para o tema escuro
    },
    secondary: {
      main: '#f48fb1', // Cor secundária para o tema escuro
    },
    // Outras personalizações de cores, se necessário
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
      color: '#fff', // Títulos em branco para melhor contraste
    },
    // Outras personalizações de tipografia
  },
  // Outras personalizações globais
});

export default theme;
